<template>
  <div class="home-container">
    <div class="home-container1">
      <div class="home-container2">
        <img alt="image" src="/playground_assets/3.svg" class="home-image" />
        <span class="home-s-c-s">Solocode Solutions</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    title: 'Solocode Solutions',
    meta: [
      {
        property: 'og:title',
        content: 'Solocode Solutions',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-bgs-bg1);
}
.home-container1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container2 {
  flex: 0 0 auto;
  width: 862px;
  height: 353px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image {
  width: var(--dl-size-size-xxlarge);
  align-self: center;
  object-fit: cover;
}
.home-s-c-s {
  color: var(--dl-color-primary-main);
  font-size: 36px;
  align-self: center;
  font-family: Noto Sans;
}
</style>
